* {
  margin: 0;
  padding: 0;
}

.active {
  color: #F1E511 !important;
  text-decoration: underline;
}

.blockStat {
  display: flex;
  flex-direction: column;
}

.stat-agency {
  color: red;
  font-weight: 900;
  text-decoration: underline;
}

.stat-title {
  font-weight: 900;
  color: #203864;
  text-decoration: underline;
}

.stat-number {
  color: #203864;
  font-weight: 900;
}