.scroll-container {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.scroll-content {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #024873 #F1E511;
  height: 100%;
}

.rtl {
  direction: rtl;
}

.rtl > * {
  direction: ltr;
}

.ltr {
  direction: ltr;
}

.scroll-content::-webkit-scrollbar {
  width: 10px;
}

.scroll-content::-webkit-scrollbar-track {
  background: #024873;
}

.scroll-content::-webkit-scrollbar-thumb {
  background-color: #F1E511;
  border: 2px solid #024873;
  border-radius: 9999px;
  padding: 10px 10px;
}
