.main {
  display: "flex";
  margin-top: "10vh";
  flex-direction: "column";
  justify-content: "center";
  align-items: "center";
}

.forButton {
  margin-top: 0;
}

.forButton.putAbsolute {
  position: "absolute";
  bottom: "0";
}

.text {
  color: "black";
  font-size: "1vw";
  cursor: "pointer";
}
